.dashboard {
  padding-bottom: 50px;

  &-wrapper {
    display: flex;
    column-gap: 150px;
    width: 100%;

    @media screen and (max-width: 1700px) {
      column-gap: 5vw;
    }
  }

  &-main {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 60px;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        h4 {
          color: var(--main-gray);
        }
      }

      &-value {
        font-size: 3rem;
        font-weight: 600;

        span {
          font-size: 1.5rem;
        }
      }

      &-decor {
        position: absolute;
      }

      &:nth-child(1) .dashboard-main__item-decor {
        right: -10px;
        top: -10px;
      }
      &:nth-child(2) .dashboard-main__item-decor {
        right: 20px;
        top: -30px;
      }
    }

    &-list {
      grid-column: 2 span;

      &-empty {
        margin: 20px 0;
        text-align: center;

        img {
          width: 100px;
          opacity: .6;
        }
        h4 {
          opacity: 0.6;
          font-weight: 500;
          margin-top: 15px;
        }
      }

      &-top {
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 15px;
        margin-bottom: 20px;
      }

      &-wrap {
        padding-bottom: 15px;
      }

      &-head,
      &__item {
        display: grid;
        grid-template-columns: 30% 10% 20% 15% 25%;
        align-items: center;
      }

      &-head {
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--secondary-gray);
        align-items: center;

        h4 {
          color: var(--main-gray);
          font-weight: 600;

          &.has-sort {
            display: flex;
            align-items: center;

            button {
              margin-left: 5px;

              &.active {
                i {
                  color: var(--main-black);
                }
              }
            }
            i {
              display: flex;
              color: var(--main-gray);
            }
          }

          
        }
      }

      &__item {
        margin-top: 20px;
        font-size: 16px;

        .icon {
          width: 32px;
          height: 32px;
        }

        a {
          h4 {
            font-size: 1em;
            width: fit-content;
            padding-bottom: 2px;
            border-bottom: 1.5px solid transparent;

            transition: 0.4s ease;
          }

          &:hover h4 {
            border-color: var(--main-black);
          }
          &:active h4, &:focus {
            color: var(--main-blue);
            border-color: var(--main-blue);
          }
        }

        h4 {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            font-weight: 600;
          }
          &:nth-child(4) {
            display: flex;
            align-items: center;
            svg {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  &-activities {
    width: 30%;

    .title {
      margin-bottom: 20px;
    }

    &-list {
      overflow-y: auto;
      max-height: 80vh;
    }

    &__item {
      position: relative;
      margin-top: 40px;
      padding: 20px;
      border-radius: 15px;
      color: white;

      &:first-child {
        margin-top: 0;
      }

      background: radial-gradient(82.32% 84.30% at 91.45% 21.93%, #FEDE35 0%, rgba(254, 222, 53, 0.00) 100%), linear-gradient(170deg, #FDCC5D 0%, #FDBA3A 100%);

      & > h4 {
        font-weight: 400;
        opacity: 0.8;
      }

      .test-goto {
        position: absolute;
        right: 10px;
        top: 10px;

        border: none;

        i {
          color: white;
        }
        
        &:hover, &:focus {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      &-bottom {
        display: flex;
        margin-top: 15px;

        h5 {
          margin-left: 10px;
          padding: 2px 8px;
          border-radius: 8px;

          background-color: white;
          color: #517FDF;
        }
      }

      &-time {
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        font-weight: 500;
        color: var(--main-gray);
      }
    }
  }
}
