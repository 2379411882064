@import './fonts';
@import './libs/normalize';
@import './libs/skeleton';

:root {
  --main-wrapper: 1600px;
  --main-black: #333333;
  --main-gray: #999999;
  --main-blue: #4c75f2;
  --secondary-gray: #d7d7d7;

  font-size: 16px;

  @media screen and (max-width: 1700px) {
    --main-wrapper: 85%;
    font-size: 1vw;
  }
}

body {
}

h2 {
  font-weight: 500;
}
h4 {
  font-weight: 400;
}

.app-wrapper {
  margin: 0 auto;
  max-width: var(--main-wrapper);
}

.main-scroll {
  padding-right: 5px;
  scrollbar-color: var(--secondary-gray) white;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-gray);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
}

.title {
  width: fit-content;
  position: relative;
  padding-bottom: 8px;
  font-weight: 600;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 65px;
    height: 3px;
    background-color: black;
  }
}

.tooltip {
  position: relative;

  &-msg {
    position: absolute;
    bottom: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);

    white-space: nowrap;
    padding: 3px 10px;
    border-radius: 8px;
    background-color: rgba($color: #000000, $alpha: 0.4);
    color: white;
    opacity: 0;
    pointer-events: none;

    transition: 0.4s ease;
  }

  &:hover {
    .tooltip-msg {
      opacity: 1;
    }
  }
}

.pagination {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    color: var(--main-black);
    transition: 0.4s ease;

    &:hover {
      color: var(--main-blue);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

.icon {
  display: inline-flex;
  color: var(--main-black);
  font-size: 1.5rem;

  &--loading {
    animation: loading 0.8s linear infinite;
    @keyframes loading {
      to {
        transform: rotate(360deg);
      }
    }
  }

  &--btn {
    cursor: pointer;
    transition: 0.2s ease;

    &:active {
      transform: scale(0.8);
    }
  }
}

.main-block {
  padding: 30px;
  border-radius: 15px;
  border: 1px solid var(--secondary-gray);
}
