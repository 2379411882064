.icon-button {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--secondary-gray);

  i {
    font-size: 18px;
    transition: 0.4s ease;
  }

  transition: 0.4s ease;

  &:hover,
  &:focus {
    background-color: var(--main-blue);
    i {
      color: white;
    }
  }
}
