.header {
  width: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;

  hr {
    flex: 1;
    border: none;
    width: 100%;
    height: 1px;
    background-color: #d7d7d7;

    &.spacer {
      flex: 0 0 30px;
    }
  }

  &-wrapper {
    width: var(--main-wrapper);
    display: flex;
    align-items: center;

  }
  
  &-select {
    width: 200px;
  }

  &-logo {
    display: flex;
    align-items: center;
    margin-right: 15px;

    & > div {
      margin-left: 10px;
    }

    h4 {
      font-weight: 400;
      color: var(--main-gray);
    }
  }

  &-back {
    padding-right: 10px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;

    border-bottom: 1.6px solid transparent;
    transition: 0.4s ease;

    &:hover {
      border-color: var(--main-gray);
    }
    &:active,
    &:focus {
      border-color: var(--main-blue);
      h3,
      i {
        color: var(--main-blue);
      }
    }

    h3 {
      font-weight: 500;
      color: var(--main-gray);
      transition: 0.4s ease;
    }
    i {
      margin-right: 5px;
      color: var(--main-gray);
      transition: 0.4s ease;
    }
  }
}
