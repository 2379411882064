// Loading
.skeleton {
  position: relative;
  pointer-events: none;
  background: none !important;
  box-shadow: none !important;
  color: transparent !important;
  user-select: none;
}
.skeleton::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background: linear-gradient(
    90deg,
    rgb(245, 245, 245) 25%,
    rgb(240, 240, 240) 50%,
    rgb(245, 245, 245) 85%
  );
  background-size: 200% 200%;
  animation-name: thumb;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  z-index: 10;
  pointer-events: none;
}
.skeleton._border::after {
  border-radius: inherit;
}
.skeleton._shadow::after {
  box-shadow: 0 4px 15px 0 rgba($color: #000000, $alpha: 0.2);
}

.skeleton-inner {
  pointer-events: none;
}
.skeleton-inner > * {
  position: relative;
  pointer-events: none;
  background: none !important;
  box-shadow: none !important;
  color: transparent !important;
  user-select: none;
  border: none !important;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(
      90deg,
      rgb(245, 245, 245) 25%,
      rgb(240, 240, 240) 50%,
      rgb(245, 245, 245) 85%
    );
    background-size: 200% 200%;
    border-radius: 50px;
    animation-name: thumb;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    z-index: 10;
    pointer-events: none;
  }
}
.skeleton-inner._shadow > *::after {
  box-shadow: 0 4px 15px 0 rgba($color: #000000, $alpha: 0.2);
}
.skeleton-inner._border > *::after {
  border-radius: inherit;
}

@keyframes thumb {
  0% {
    background-position: -50% 0%;
  }
  100% {
    background-position: -250% 0%;
  }
}
