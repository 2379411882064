.input {
  position: relative;

  label {
    position: absolute;
    top: 50%;
    left: 15px;
    font-weight: 600;
    background-color: white;

    color: var(--main-gray);
    pointer-events: none;

    transform: translateY(-50%);
    transition: 0.2s ease;
  }

  input {
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid var(--secondary-gray);
    font-size: 1rem;
    background-color: white;
    transition: 0.4s ease;

    &:valid,
    &:active,
    &:focus {
      border-color: var(--main-blue);

      & + label {
        top: 0px;
        font-size: 0.8rem;

        & + button {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &-hide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    transition: 0.4s ease;
    opacity: 0;
    pointer-events: none;
  }

  &.invalid {
    animation: drag 0.5s ease 1;
    @keyframes drag {
      from {
        transform: translateX(0);
      }
      33% {
        transform: translateX(5px);
      }
      66% {
        transform: translateX(-5px);
      }
      to {
        transform: translateX(0);
      }
    }

    input {
      border-color: #f32d2d;
    }
  }
}
