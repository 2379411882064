.auth {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;

  &-wrapper {
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-logo {
    margin-top: 15%;
    width: 60%;
    margin-bottom: 5%;
  }

  &-form {
    width: 400px;
    padding: 30px;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0px 15px 30px 0 rgba($color: #000000, $alpha: 0.1);

    .input + .input {
      margin-top: 12px;
    }
    input {
      width: 100%;
    }
    h1 {
      color: var(--main-black);
      margin-bottom: 30px;
    }
    &-submit {
      margin-top: 30px;
      padding: 10px 0;
      border-radius: 30px;
      width: 100%;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      i {
        color: white;
      }
      background-color: var(--main-blue);
      transition: 0.4s ease;

      &:hover {
        background-color: #3157c7;
      }

      &.disabled {
        background-color: var(--secondary-gray);
        pointer-events: none;
        cursor: auto;
      }
    }
  }

  &-credits {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: white;
    font-weight: 600;
  }
}
