.user-details {
  
  &-wrapper {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 30px;
    row-gap: 30px;
    padding-bottom: 50px;

    @media screen and (max-width: 1700px) {
      grid-template-columns: 40% 40%;
    }

    .title {
      margin-bottom: 20px;
    }
  }

  &-card {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid var(--secondary-gray);

    &-info {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img {
        margin-right: 20px;
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
      }

      h4 {
        margin-top: 5px;
        width: fit-content;
        padding: 2px 8px;
        border-radius: 10px;
        color: white;
        background: var(--blue-2, linear-gradient(90deg, #1a4fcb 0%, #4378ff 100%));
      }
    }

    p {
      color: var(--main-gray);
      margin-top: 5px;
    }
  }

  &-aim {
    position: relative;
    padding: 30px;
    padding-top: 80px;

    & > h3 {
      position: absolute;
      top: 20px;
      left: 20px;
      color: white;
    }

    canvas {
      width: 100% !important;
      height: 100px;
    }

    &-start {
      position: absolute;
      top: 80px;
      bottom: 35px;
      left: 35px;
      border-left: 1px dashed white;

      h4 {
        transform: translateX(-50%) translateY(-100%);
        color: white;
        font-weight: 600;
      }
    }

    &-end {
      position: absolute;
      top: 80px;
      bottom: 35px;
      right: 35px;
      border-right: 1px dashed white;

      h4 {
        transform: translateX(50%) translateY(-100%);
        color: white;
        font-weight: 600;
      }
    }

    border-radius: 15px;
    background: linear-gradient(140deg, #94c463 0%, #afcf6a 51.04%, #37bb74 100%);
  }

  &-activity {
    padding: 15px !important;

    .activity {
      grid-row: 2 span;
      display: flex;
      flex-direction: column;

      &-list {
        overflow-y: auto;
        height: 200px;
      }

      &__item {
        margin-top: 20px;
        padding: 10px 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-details {
          position: absolute;
          width: 100%;
          padding: 10px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scaleY(0.2);

          color: white;
          font-size: 14px;

          opacity: 0;
          pointer-events: none;
          transition: 0.5s ease;

          .duration {
            font-size: 13px;
            text-align: right;
          }
          &-title {
            display: flex;
            align-items: center;
            overflow: hidden;

            span {
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; /* number of lines to show */
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            .icon {
              flex-shrink: 0;
              width: 24px;
              height: 24px;
              path,
              circle {
                fill: white;
              }
            }
          }
          .label {
            position: absolute;
            left: 15px;
            bottom: 10px;
            font-size: 12px;
            opacity: 0.6;
            text-transform: uppercase;
          }
        }

        &-event {
          position: relative;
          background-color: #517fdf;
          cursor: pointer;
          transition: 0.4s ease;

          &:hover {
            width: 200% !important;
            transform: scaleY(5);
            border-radius: 100px !important;
            .activity__item-details {
              opacity: 1;
              pointer-events: all;
            }
          }

          &--test {
            background: radial-gradient(
                80.74% 80.74% at 91.45% 21.93%,
                #fede35 0%,
                rgba(254, 222, 53, 0) 100%
              ),
              linear-gradient(173.16deg, #fdcc5d 5.36%, #fdba3a 96.58%);
          }
          &--lesson {
          }
        }

        &-timeline {
          position: relative;
          margin-left: 40px;
          margin-right: 20px;
          width: 100%;
          height: 10px;
          background-color: rgba(#517fdf, 0.2);
          border-radius: 15px;

          display: flex;

          &-date {
            position: absolute;
            bottom: -20px;
            font-size: 14px;
            color: #a4b8e2;

            &.start-date {
              left: 0;
              transform: translateX(-50%);
            }
            &.end-date {
              right: 0;
              transform: translateX(50%);
            }
          }
        }

        &-date {
          width: 40px;
          flex-shrink: 0;
          text-align: center;
        }

        &-eventnull {
          position: relative;
          &:hover .activity__item-duration {
            opacity: 1;
            pointer-events: all;
          }
        }
        &-duration {
          position: absolute;
          bottom: calc(100% + 2px);
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          padding: 2px 8px;
          background-color: rgba(0, 0, 0, 0.4);
          color: white;
          font-size: 13px;
          border-radius: 5px;
          pointer-events: none;
          opacity: 0;
          transition: 0.5s ease;
        }

        .ui-btnIcon {
          flex-shrink: 0;
        }
      }

      @media screen and (max-width: 1400px) {
        height: auto;
        min-height: 320px;
      }
    }

    @media screen and (max-width: 1500px) {
      grid-column: 2 span;

      .activity__item-date {
        width: 60px;
        font-size: 18px;
      }
    }
  }

  &-list {
    grid-column: 2 span;

    .percent-circle {
      width: 48px;
      height: 48px;
      position: relative;

      h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
      }
    }

    &__item {
      padding: 10px 5px;
      border-radius: 5px;

      display: flex;
      align-items: center;
      transition: 0.4s ease;

      &:first-child {
        margin-top: 0px;
      }

      &-block {
        margin-left: 15px;
        padding: 3px 15px;
        border-radius: 30px;
        background: var(--jaryq-blue, linear-gradient(45deg, #1c52c5 0%, #4478ff 88.47%));
        color: white;
      }

      &-date {
        margin-left: auto;
        font-weight: 500;
        color: var(--main-gray);
      }

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.03);
      }
    }
  }

  &-statistic {
    @media screen and (max-width: 1500px) {
      grid-column: 2 span;

      h6.label {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        opacity: 0.5;
        text-align: right;
        width: 50%;
      }

      &-select {
        flex: 1;
        margin-right: 15px;
      }

      &-filter {

        button {
          border-radius: 30px;
          padding: 5px 10px;
          font-weight: 600;
          background-color: #e3e3e3;
          color: #858585;

          transition: 0.4s ease;
          cursor: pointer;

          & + button {
            margin-left: 5px;
          }

          &:hover,
          &.active {
            background-color: rgba(#517fdf, 0.2);
            color: #517fdf;

            &:nth-child(1) {
              background-color: #c0f08f;
              color: #4e8516;
            }
            &:nth-child(2) {
              background-color: #f08f8f;
              color: #851616;
            }
          }
        }
      }
    }

    .themes {
      flex: 1;
      height: 300px;
      overflow-y: auto;
      cursor: auto;

      &__item {
        padding: 15px 0;

        @media screen and (max-width: 1400px) {
          padding: 10px 0;
        }

        & > a {
          width: 94%;
          margin-bottom: 5px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          transition: 0.4s ease;
          &:hover {
            color: #517fdf;
          }
        }

        .progress {
          display: flex;
          align-items: center;
          justify-content: stretch;

          &-bar {
            position: relative;
            width: 100%;
            height: 7px;
          }

          .overlay,
          .value {
            border-radius: 10px;
            position: absolute;
            width: 100%;
            height: 100%;
            background: inherit;
            opacity: 0.2;
            transition: 0.8s ease;
          }

          .value {
            opacity: 1;
          }
        }
        .percent {
          margin-left: 15px;
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
  }
}
